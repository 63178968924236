import cn from 'classnames'
import { useRouter } from 'next/router'
import React, { createContext, useCallback, useEffect, useState } from 'react'
import LazyLoad from 'react-lazy-load'
import config from '../../config.json'
import HomepageTopSlider from '../banner/HomepageTopSlider'
import Banner from '../banner/Banner'
import ScrollUpIcon from '../common/util/ScrollUpIcon'
import Footer from '../footer'
import Header from '../Header/Header'
import { BreadcrumbItem, Breadcrumbs } from '@nextui-org/react'

const Page = ({
    id,
    classNames,
    title,
    image,
    description,
    children,
    hasContentScroll,
    banners = true,
    breadcrumbs = [],
    pageTitle,
    pageSubtitle,
}) => {
    if (!title) title = config.titles.main
    if (!image) image = 'https://www.nfthive.io/nfthive-logo.png'
    if (!description) description = description = config.descriptions.main

    const [isClient, setIsClient] = useState(false)

    useEffect(() => {
        setIsClient(typeof window !== 'undefined')
    }, [])

    const router = useRouter()
    const location = router.asPath.substring(
        0,
        router.asPath.indexOf('?') > 0
            ? router.asPath.indexOf('?')
            : router.asPath.length,
    )
    //banner only on default path
    const isDefaultPath = router.pathname === '/'

    const hasScrollDown = ['/bulk'].includes(location)

    const [scrollManager, setScrollManager] = useState({
        useManager: false,
        triggered: false,
    })

    const [showScrollDown, setShowScrollDown] = useState(false)
    const [showScrollUpIcon, setShowScrollUpIcon] = useState(false)

    const scrollUp = async () => {
        if (typeof window !== 'undefined') {
            const element = document.getElementById(id)

            const tabs = document.getElementById('TopContainer')
            if (tabs) {
                const box = tabs.getBoundingClientRect()
                element.scrollTo({
                    left: box.left,
                    top: box.height,
                    behavior: 'smooth',
                })
            } else {
                element.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
            }
        }
    }

    const scrollUpFunc = async () => {
        if (scrollManager.scrollUp) {
            scrollUp()
            const newManager = scrollManager
            newManager.scrollUp = false
            setScrollManager(newManager)
        }
    }

    useEffect(() => {
        scrollUpFunc()
    }, [scrollManager.scrollUp])

    const handleScroll = useCallback(async (e) => {
        let element = e.target

        if (!showScrollUpIcon && element.scrollTop > element.clientHeight) {
            setShowScrollUpIcon(true)
        } else if (
            showScrollUpIcon &&
            element.scrollTop < element.clientHeight
        ) {
            setShowScrollUpIcon(false)
        }

        if (
            element.scrollHeight - element.scrollTop - element.clientHeight <
                600 &&
            showScrollDown &&
            hasScrollDown
        ) {
            setShowScrollDown(false)
        } else if (
            element.scrollHeight - element.scrollTop - element.clientHeight <
                100 &&
            scrollManager.useManager &&
            !scrollManager.triggered &&
            !scrollManager.scrollUp
        ) {
            const newManager = {
                useManager: scrollManager.useManager,
                offset: scrollManager.offset,
                triggered: true,
                scrollUp: scrollManager.scrollUp,
                lastResult: scrollManager.lastResult,
                lastSearch: scrollManager.lastSearch,
            }
            setScrollManager(newManager)
        } else if (
            element.scrollHeight - element.scrollTop - element.clientHeight >
                600 &&
            !showScrollDown &&
            hasScrollDown
        ) {
            setShowScrollDown(true)
        }
    })

    return (
        <div
            className={cn(
                'overflow-x-hidden InsideScroll',
                'm-auto ml-0 font-light w-full',
                'overflow-y-auto h-full',
                classNames,
            )}
            id={id}
            onScroll={handleScroll}
        >
            <Header title={title} description={description} image={image} />

            {banners && <Banner />}

            {/* {isDefaultPath && banners && <HomepageTopSlider />} */}

            {breadcrumbs.length > 0 && pageTitle ? (
                <div className="px-4 py-4">
                    <Breadcrumbs>
                        {breadcrumbs.map((item, index) => (
                            <BreadcrumbItem
                                key={index}
                                href={item?.link || '/'}
                                classNames={{
                                    root: 'text-white',
                                    separator: '!text-white',
                                    item: 'text-white',
                                }}
                            >
                                <p>{item?.title}</p>
                            </BreadcrumbItem>
                        ))}
                    </Breadcrumbs>
                    <h1 className="mt-2 text-2xl font-semibold lg:text-4xl sm:text-3xl">
                        {pageTitle}
                    </h1>
                    <h2 className="my-2 text-base font-light dark:text-gray-400 text-gray-600 ">
                        {' '}
                        {pageSubtitle}
                    </h2>
                </div>
            ) : null}

            {/* {hasScrollDown && showScrollDown && (
                <div
                    onClick={scrollDown}
                    className={cn(
                        'absolute p-4 right-12 md:right-56 top-0 md:top-14',
                        'bg-blue z-50 cursor-pointer',
                        'rounded-2xl shadow',
                    )}
                >
                    <div className={'flex justify-center'}>
                        <div className={'text-white'}>Finish</div>
                        <div className={cn('h-6 w-6 animate-bounce')}>
                            <img
                                className={'m-1'}
                                src={'/arrowhead-down-outline.svg'}
                                alt={''}
                            />
                        </div>
                    </div>
                </div>
            )} */}
            <div>
                <LazyLoad offset={100}>
                    <div
                        className={cn(
                            'flex flex-wrap justify-center hidden',
                            'w-full max-w-700px rounded-xl shadow overflow-hidden mx-auto mt-8',
                        )}
                    >
                        <img
                            src={image}
                            alt={'NFTHive Logo - WAX NFT Marketplace'}
                        />
                    </div>
                </LazyLoad>
            </div>

            <div className={'min-h-200'}>
                {isClient ? (
                    <ScrollContext.Provider
                        value={{
                            scrollManager: scrollManager,
                            setScrollManager: setScrollManager,
                        }}
                    >
                        {children}
                    </ScrollContext.Provider>
                ) : (
                    ''
                )}
            </div>
            {!hasContentScroll && (
                <div className={'w-full'}>
                    <Footer />
                </div>
            )}
            {showScrollUpIcon && <ScrollUpIcon onClick={scrollUp} />}
        </div>
    )
}

export const ScrollContext = createContext({
    scrollManager: {
        useManager: false,
        triggered: false,
    },
    setScrollManager: () => {},
})
export default Page
