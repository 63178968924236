import { Radio, RadioGroup } from '@mui/material'
import cn from 'classnames'
import moment from 'moment'
import { useContext, useEffect, useState } from 'react'
import { get } from '../helpers/Api'
import LoadingIndicator from '../loadingindicator'
import { Context } from '../waxplorer/Waxplorer'
import { BannerLink } from './BannerLink'

const Banner = () => {
    const [state, dispatch] = useContext(Context)

    const [timer, setTimer] = useState(null)

    const minute = moment().minutes()

    const [counter, setCounter] = useState(
        state.bannerCounter || state.bannerCounter === 0
            ? state.bannerCounter
            : minute,
    )

    let banners = state.banners

    const parseBanners = (res) => {
        dispatch({ type: 'SET_BANNERS', payload: res })
    }

    useEffect(() => {
        const counterToSet = counter
        if (!banners) {
            get(`all-banners?source=nfthive`, 'api').then(parseBanners)
        }
        if (timer) {
            clearTimeout(timer)
        }
        setTimer(
            setTimeout(() => {
                dispatch({
                    type: 'SET_BANNER_COUNTER',
                    payload: counterToSet + 1,
                })
                setCounter(counter + 1)
            }, 60000),
        )
    }, [counter])

    const handleChecked = (e) => {
        if (timer) {
            clearTimeout(timer)
            setCounter(parseInt(e.target.value))
        }
    }

    return (
        <div className={cn('relative flex justify-center mx-auto px-2 py-4')}>
            {banners ? (
                <div
                    className={cn(
                        'relative w-auto grid',
                        { 'lg:grid-cols-3': banners.length > 2 },
                        { 'lg:grid-cols-2': banners.length === 2 },
                        { 'lg:grid-cols-3': banners.length < 2 },
                    )}
                >
                    {banners.length > 1 ? (
                        <BannerLink
                            banner={banners[counter % banners.length]}
                            mDisplay={'hidden'}
                        />
                    ) : (
                        <div />
                    )}
                    {banners.length > 1 ? (
                        <BannerLink
                            banner={banners[(counter + 1) % banners.length]}
                            mDisplay={'flex'}
                        />
                    ) : banners.length === 1 ? (
                        <BannerLink
                            banner={banners[counter % banners.length]}
                            mDisplay={'flex'}
                        />
                    ) : null}
                    {banners.length > 2 ? (
                        <BannerLink
                            banner={banners[(counter + 2) % banners.length]}
                            mDisplay={'hidden'}
                        />
                    ) : null}
                    {banners && banners.length > 3 ? (
                        <div className={'absolute bottom-0 right-4'}>
                            <RadioGroup defaultValue={0}>
                                <div className={'flex'}>
                                    {banners &&
                                        banners.map((item, index) => (
                                            <Radio
                                                value={index}
                                                checked={
                                                    counter % banners.length ===
                                                    parseInt(index)
                                                }
                                                onClick={handleChecked}
                                                key={index}
                                            />
                                        ))}
                                </div>
                            </RadioGroup>
                        </div>
                    ) : null}
                </div>
            ) : process.env.NEXT_PUBLIC_TESTNET === 'TRUE' ? (
                ''
            ) : (
                <LoadingIndicator />
            )}
        </div>
    )
}

export default Banner
