import Head from 'next/head'
import React from 'react'

export default function Header({ title, description, image }) {
    return (
        <Head>
            <title>{title}</title>
            <meta id="og-title" property="og:title" content={title} />
            <meta name="description" content={description} />
            <link rel="canonical" href="https://nfthive.io/" />
            <meta
                name="keywords"
                content="WAX.io, $WAXP, NFT, NFT market, NFT marketplace, Sell NFTs, Buy NFTs, Trade NFTs, metaverse, blockchain, play to earn, p2e"
            />
            <meta id="og-image" property="og:image" content={image} />
            <meta id="twitter-title" property="twitter:title" content={title} />
            <meta
                id="twitter-description"
                property="twitter:description"
                content={description}
            />
            <meta id="twitter-image" property="twitter:image" content={image} />
            <link rel="icon" href="https://nfthive.io/favicon.ico" />
            <link
                id="page-image"
                rel="apple-touch-icon"
                href="https://nfthive.io/apple-touch-icon.png"
            />
            <link
                rel="icon"
                type="image/png"
                sizes="16x16"
                href="https://nfthive.io/favicon-16x16.png"
            />
            <link
                rel="icon"
                type="image/png"
                sizes="32x32"
                href="https://nfthive.io/favicon-32x32.png"
            />
            <link
                rel="icon"
                type="image/png"
                sizes="96x96"
                href="https://nfthive.io/favicon-96x96.png"
            />
            <meta name="msapplication-TileColor" content="#F6C30D" />
            <meta name="theme-color" content="#1A1A1A" />
            {image && image.includes('.gif') ? (
                <meta content="image/gif" property="og:image:type" />
            ) : (
                ''
            )}
        </Head>
    )
}
