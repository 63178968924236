import cn from 'classnames'
import { useContext } from 'react'
import { Context } from '../waxplorer/Waxplorer'

export const BannerLink = ({ banner, mDisplay = 'hidden' }) => {
    const [state] = useContext(Context)

    return (
        <a
            href={banner['url']}
            target="_blank"
            className={cn(
                mDisplay,
                'lg:flex justify-center mb-0 w-auto px-2 h-full',
            )}
            rel="noreferrer"
        >
            {banner['image'].includes('video:') ? (
                <video
                    autoPlay={true}
                    muted={true}
                    playsInline={true}
                    loop
                    src={
                        (state.playAnimations
                            ? 'https://ipfs.hivebp.io/ipfs/'
                            : 'https://ipfs.hivebp.io/frozen/') +
                        banner['image'].replace('video:', '')
                    }
                    alt="banner"
                    className={'rounded-xl max-h-40'}
                />
            ) : (
                <img
                    src={
                        state.playAnimations
                            ? banner['image']
                            : banner['image'].replace(
                                  'hivebp.io/ipfs',
                                  'hivebp.io/frozen',
                              )
                    }
                    alt="banner"
                    className={'rounded-xl max-h-40'}
                />
            )}
        </a>
    )
}
