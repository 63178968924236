import cn from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Link from '../common/util/input/Link'

const links = [
    {
        label: 'navigation.collections',
        href: '/collections',
    },
    {
        label: 'search.market',
        href: '/market',
    },
    {
        label: 'navigation.analytics',
        href: '/stats',
    },
    {
        label: 'navigation.bulk',
        href: '/bulkoverview',
    },
    {
        label: 'navigation.rent_cpu',
        href: '/cpu',
    },
]

const companyLinks = [
    {
        label: 'FAQ',
        href: '/faq',
    },
    {
        label: 'Imprint',
        href: '/imprint',
    },
    {
        label: 'Terms of Use',
        href: '/tou',
    },
    {
        label: 'waxmarketcap.com',
        href: 'https://waxmarketcap.com/',
    },
    {
        label: 'Hive-E Telegram Listing Bot',
        href: 'https://t.me/WAXplorerBot',
    },
    {
        label: 'Testnet Creator',
        href: 'https://test.nfthive.io',
    },
]

function Footer() {
    const { t } = useTranslation('common')

    const LinksList = () => {
        return links.map((link, idx) => (
            <li className={cn('mb-1')} key={idx}>
                <Link href={link.href}>{t(link.label)}</Link>
            </li>
        ))
    }

    const CompanyList = () => {
        return companyLinks.map((link, idx) => (
            <li className={cn('mb-1')} key={idx}>
                <Link href={link.href} external={link.href.includes('http')}>
                    {t(link.label)}
                </Link>
            </li>
        ))
    }

    const medium_link = 'https://hivebp.medium.com/'
    const discord_link = 'https://discord.gg/y47qvSmaVu'
    const twitter_link = 'https://twitter.com/nfthiveio'
    const telegram_link = 'https://t.me/NFTHiveTalk'

    const currentYear = new Date().getFullYear()

    return (
        <div
            className={cn(
                'w-full bg-zinc-50 dark:bg-zinc-900 pt-8 pb-3 mt-4 border-t border-zinc-200 dark:border-zinc-800',
            )}
        >
            <div
                className={cn(
                    'container mx-auto',
                    'dark:text-white text-black font-bold text-base mb-4',
                    'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-8  lg:gap-12',
                )}
            >
                {/* new description */}
                <div
                    className={'mr-auto ml-4 flex flex-col md:hidden lg:block '}
                >
                    <div className={''}>
                        <Link href={'/'}>
                            <img
                                src="/nfthive-logo.svg"
                                alt="NFTHive on WAX"
                                className={cn(
                                    'relative h-8 md:h-4 lg:h-8 xl:h-12 cursor-pointer mt-1 hidden dark:!block',
                                )}
                            />
                            <img
                                src="/nfthive-logo-black.svg"
                                alt="NFTHive on WAX"
                                className={cn(
                                    'relative h-8 md:h-4 lg:h-8 xl:h-12 cursor-pointer mt-1 dark:hidden',
                                )}
                            />
                        </Link>
                    </div>
                    <div className="">
                        <p
                            className={cn(
                                'text-base font-normal',
                                'mt-4 mb-4 text-left md:text-left lg:text-left xl:text-left  ',
                            )}
                        />
                    </div>
                </div>
                <div className={'mr-auto ml-4'}>
                    <h4 className={cn('text-primary font-bold text-xl mt-2')}>
                        Market Links
                    </h4>
                    <ul className={cn('text-base font-normal', 'mt-4 mb-4')}>
                        <LinksList />
                    </ul>
                </div>
                <div className={'mr-auto ml-4'}>
                    <h4 className={cn('text-primary font-bold text-xl mt-2')}>
                        Company Links
                    </h4>
                    <ul className={cn('text-base font-normal', 'mt-4 mb-4')}>
                        <CompanyList />
                    </ul>
                </div>
                <div className={'mr-auto ml-4'}>
                    <h4 className={cn('text-primary text-xl mt-2')}>
                        Connect with Us
                    </h4>
                    <div
                        className={cn(
                            'flex justify-left mt-5',
                            'dark:text-white text-black',
                        )}
                    >
                        <div className={cn('ml-4')}>
                            <a
                                href={twitter_link}
                                className={cn(
                                    'dark:text-white text-black hover:text-primary transition-colors',
                                )}
                                target={'_blank'}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    fill="currentColor"
                                    className="bi bi-twitter"
                                    viewBox="0 0 16 16"
                                >
                                    <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
                                </svg>
                            </a>
                        </div>
                        <div className={cn('ml-4')}>
                            <a
                                href={telegram_link}
                                className={cn(
                                    'dark:text-white text-black hover:text-primary transition-colors',
                                )}
                                target={'_blank'}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    fill="currentColor"
                                    className="bi bi-telegram"
                                    viewBox="0 0 16 16"
                                >
                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.287 5.906c-.778.324-2.334.994-4.666 2.01-.378.15-.577.298-.595.442-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294.26.006.549-.1.868-.32 2.179-1.471 3.304-2.214 3.374-2.23.05-.012.12-.026.166.016.047.041.042.12.037.141-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8.154 8.154 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629.093.06.183.125.27.187.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.426 1.426 0 0 0-.013-.315.337.337 0 0 0-.114-.217.526.526 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09z" />
                                </svg>
                            </a>
                        </div>
                        <div className={cn('ml-4')}>
                            <a
                                href={discord_link}
                                className={cn(
                                    'dark:text-white text-black hover:text-primary transition-colors',
                                )}
                                target={'_blank'}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    fill="currentColor"
                                    className="bi bi-discord"
                                    viewBox="0 0 16 16"
                                >
                                    <path d="M6.552 6.712c-.456 0-.816.4-.816.888s.368.888.816.888c.456 0 .816-.4.816-.888.008-.488-.36-.888-.816-.888zm2.92 0c-.456 0-.816.4-.816.888s.368.888.816.888c.456 0 .816-.4.816-.888s-.36-.888-.816-.888z" />
                                    <path d="M13.36 0H2.64C1.736 0 1 .736 1 1.648v10.816c0 .912.736 1.648 1.64 1.648h9.072l-.424-1.48 1.024.952.968.896L15 16V1.648C15 .736 14.264 0 13.36 0zm-3.088 10.448s-.288-.344-.528-.648c1.048-.296 1.448-.952 1.448-.952-.328.216-.64.368-.92.472-.4.168-.784.28-1.16.344a5.604 5.604 0 0 1-2.072-.008 6.716 6.716 0 0 1-1.176-.344 4.688 4.688 0 0 1-.584-.272c-.024-.016-.048-.024-.072-.04-.016-.008-.024-.016-.032-.024-.144-.08-.224-.136-.224-.136s.384.64 1.4.944c-.24.304-.536.664-.536.664-1.768-.056-2.44-1.216-2.44-1.216 0-2.576 1.152-4.664 1.152-4.664 1.152-.864 2.248-.84 2.248-.84l.08.096c-1.44.416-2.104 1.048-2.104 1.048s.176-.096.472-.232c.856-.376 1.536-.48 1.816-.504.048-.008.088-.016.136-.016a6.521 6.521 0 0 1 4.024.752s-.632-.6-1.992-1.016l.112-.128s1.096-.024 2.248.84c0 0 1.152 2.088 1.152 4.664 0 0-.68 1.16-2.448 1.216z" />
                                </svg>
                            </a>
                        </div>
                        <div className={cn('ml-4')}>
                            <a
                                href={medium_link}
                                className={cn(
                                    'dark:text-white text-black hover:text-primary transition-colors',
                                )}
                                target={'_blank'}
                            >
                                <svg
                                    width="24"
                                    height="24"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="currentColor"
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                >
                                    <path d="M2.846 6.887c.03-.295-.083-.586-.303-.784l-2.24-2.7v-.403h6.958l5.378 11.795 4.728-11.795h6.633v.403l-1.916 1.837c-.165.126-.247.333-.213.538v13.498c-.034.204.048.411.213.537l1.871 1.837v.403h-9.412v-.403l1.939-1.882c.19-.19.19-.246.19-.537v-10.91l-5.389 13.688h-.728l-6.275-13.688v9.174c-.052.385.076.774.347 1.052l2.521 3.058v.404h-7.148v-.404l2.521-3.058c.27-.279.39-.67.325-1.052v-10.608z" />
                                </svg>
                            </a>
                        </div>
                    </div>
                    <h4 className={cn('text-primary text-xl mt-4')}>
                        Report a Problem
                    </h4>
                    <div className={cn('flex justify-left mt-5')}>
                        <div className={cn('ml-4')}>
                            <a
                                href={
                                    'https://discord.com/channels/806257237705621514/905191562990977024'
                                }
                                target={'_blank'}
                                className={cn(
                                    'dark:bg-zinc-900 bg-white hover:text-primary transition-colors',
                                )}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    fill="currentColor"
                                    className="bi bi-discord"
                                    viewBox="0 0 16 16"
                                >
                                    <path d="M6.552 6.712c-.456 0-.816.4-.816.888s.368.888.816.888c.456 0 .816-.4.816-.888.008-.488-.36-.888-.816-.888zm2.92 0c-.456 0-.816.4-.816.888s.368.888.816.888c.456 0 .816-.4.816-.888s-.36-.888-.816-.888z" />
                                    <path d="M13.36 0H2.64C1.736 0 1 .736 1 1.648v10.816c0 .912.736 1.648 1.64 1.648h9.072l-.424-1.48 1.024.952.968.896L15 16V1.648C15 .736 14.264 0 13.36 0zm-3.088 10.448s-.288-.344-.528-.648c1.048-.296 1.448-.952 1.448-.952-.328.216-.64.368-.92.472-.4.168-.784.28-1.16.344a5.604 5.604 0 0 1-2.072-.008 6.716 6.716 0 0 1-1.176-.344 4.688 4.688 0 0 1-.584-.272c-.024-.016-.048-.024-.072-.04-.016-.008-.024-.016-.032-.024-.144-.08-.224-.136-.224-.136s.384.64 1.4.944c-.24.304-.536.664-.536.664-1.768-.056-2.44-1.216-2.44-1.216 0-2.576 1.152-4.664 1.152-4.664 1.152-.864 2.248-.84 2.248-.84l.08.096c-1.44.416-2.104 1.048-2.104 1.048s.176-.096.472-.232c.856-.376 1.536-.48 1.816-.504.048-.008.088-.016.136-.016a6.521 6.521 0 0 1 4.024.752s-.632-.6-1.992-1.016l.112-.128s1.096-.024 2.248.84c0 0 1.152 2.088 1.152 4.664 0 0-.68 1.16-2.448 1.216z" />
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            {/* tablet size description */}
            <div
                className={
                    'mr-auto ml-4 flex-col px-8 mt-2 hidden lg:hidden md:block '
                }
            >
                <div className={''}>
                    <Link href={'/'}>
                        <img
                            src="/nfthive-logo.svg"
                            alt="NFTHive on WAX"
                            className={cn(
                                'relative h-8 md:h-4 lg:h-8 xl:h-12 cursor-pointer mt-1 hidden dark:!block',
                            )}
                        />
                        <img
                            src="/nfthive-logo-black.svg"
                            alt="NFTHive on WAX"
                            className={cn(
                                'relative h-8 md:h-4 lg:h-8 xl:h-12 cursor-pointer mt-1 dark:hidden',
                            )}
                        />
                    </Link>
                </div>
                <div className="">
                    <p
                        className={cn(
                            'text-base font-normal',
                            'mt-4 mb-4 text-left md:text-left lg:text-left xl:text-left  ',
                        )}
                    >
                        NFT Hive is the best marketplace for Wax NFTs. Explore
                        the power of NFTs, buy, sell, and create your digital
                        assets effortlessly. As the top marketplace on the Wax
                        blockchain, NFT Hive offers a dynamic platform for
                        collectors and creators alike to engage with digital art
                        and ownership.
                    </p>
                </div>
            </div>
            {/*  horizontal line */}
            <div className={cn('container mx-auto')}>
                <div className={cn('h-0.5  bg-zinc-300 dark:bg-gray-500')} />

                <div className={cn('flex justify-start mt-4 mb-2 ml-4')}>
                    <p className={cn('dark:text-white text-black  text-sm')}>
                        <b>© {currentYear} NFTHive.io</b>
                    </p>

                    <p
                        className={cn(
                            'dark:text-white text-black text-sm ml-4',
                        )}
                    >
                        <b>All Rights Reserved</b>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Footer
