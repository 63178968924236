import cn from 'classnames'
import { useState, useRef } from 'react'

import Image from 'next/image'
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide'
import '@splidejs/react-splide/css/core'
import MainButton from '../common/util/input/MainButton'

const sampleBanners = [
    {
        image: '/sampleBg.jpg',
        nft: '/sampleNft.jpeg',
        title: 'Collections',
        description:
            ' Explore a diverse range of NFT collections curated for every taste and interest',
    },
    {
        image: '/sampleBg.jpg',
        nft: '/sampleNft.jpeg',
        title: 'Marketplace',
        description:
            'Discover a vibrant marketplace where you can buy, sell, and trade NFTs with ease',
    },
    {
        image: '/sampleBg.jpg',
        title: 'Drops',
        nft: '/sampleNft.jpeg',
        description:
            'Stay updated on upcoming NFT drops from your favorite artists and creators',
    },
    {
        image: '/sampleBg.jpg',
        title: 'Live Listings',
        nft: '/sampleNft.jpeg',
        description:
            ' Find real-time listings of NFTs available for purchase, ensuring you never miss out on the latest additions',
    },
    {
        image: '/sampleBg.jpg',
        title: 'Bulk Tools',
        nft: '/sampleNft.jpeg',
        description:
            'Streamline your NFT management with efficient bulk tools for organizing and managing your collection',
    },
]

const HomepageTopSlider = () => {
    const ref = useRef(null)
    const [activeIndex, setActiveIndex] = useState(0)

    const updateSlide = (index) => {
        setActiveIndex(index)
        ref.current?.splide?.go(index)
    }

    const PaginationButton = ({ index }) => (
        <button
            className={`h-3 w-3 rounded-full ${
                activeIndex === index ? 'bg-white' : ' bg-[#969696A6]'
            }`}
            onClick={() => updateSlide(index)}
        ></button>
    )

    const CustomPrevArrow = () => (
        <button
            className="flex items-center justify-center w-7 h-7 bg-transparent border-[1px] border-[#F6C30D] rounded-full "
            onClick={() => ref?.current?.splide.go('<')}
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                alt="prev"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                className="w-5 h-5 text-[#F6C30D]"
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                />
            </svg>
        </button>
    )

    const CustomNextArrow = () => (
        <button
            className="flex items-center justify-center w-7 h-7 bg-transparent border-[1px] border-[#F6C30D] rounded-full "
            onClick={() => ref?.current?.splide.go('>')}
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                alt="next"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                className="w-5 h-5 text-[#F6C30D]"
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                />
            </svg>
        </button>
    )

    const CarouselItem = ({ item }) => {
        return (
            <div
                className={`relative h-full w-full py-4 xs:py-8 md:py-0 my-auto`}
            >
                <div className="">
                    <div className=" grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-2 max-w-[1400px] mx-auto py-0 md:py-12 p-6 ">
                        <div className="flex items-end justify-end w-full py-2 mt-2 text-center md:justify-center md:mt-0 md:items-start md:py-12 md:text-left">
                            <div className="flex items-center w-full gap-4 ">
                                <div className="hidden md:block">
                                    <CustomPrevArrow />
                                </div>
                                <div className="flex flex-col items-center w-full md:items-start">
                                    <h1 className="lg:text-[36px] font-bold text-zinc-800 dark:!text-white text-[28px] ">
                                        {item.title}
                                    </h1>
                                    <p className=" font-normal text-zinc-800 dark:!text-white text-[18px] mt-2">
                                        {item.description}
                                    </p>
                                    <div className="flex gap-2 ">
                                        <MainButton
                                            color="primary"
                                            variant="solid"
                                            className="mt-4"
                                        >
                                            REWARDS
                                        </MainButton>
                                        <MainButton
                                            color="primary"
                                            variant="bordered"
                                            className="mt-4"
                                        >
                                            LEARN MORE
                                        </MainButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex items-center justify-center order-first my-auto gap-4 md:order-last">
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <Image
                                    src={item.nft}
                                    alt="cover image"
                                    width={975}
                                    height={325}
                                    className={cn(
                                        `object-cover mx-auto max-w-[250px] max-h-[350px] h-full object-top md:max-w-[300px] lg2:max-w-[578px]  rounded-xl`,
                                    )}
                                    priority={true}
                                />
                            </div>
                            <div className="hidden md:block ">
                                <CustomNextArrow />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            <div className="relative w-full mb-8">
                <Splide
                    onMoved={(splide) => setActiveIndex(splide?.index)}
                    ref={ref}
                    hasTrack={false}
                    className="!p-0"
                    aria-label="My Favorite Images"
                    options={{
                        autoplay: true,
                        perPage: 1,
                        perMove: 1,
                        rewind: true,
                        drag: true,
                        arrows: false,
                        gap: '1rem',
                        snap: true,
                        pagination: false,
                        type: 'loop',
                    }}
                >
                    <SplideTrack>
                        {sampleBanners.length > 1
                            ? sampleBanners?.map((item) => (
                                  <SplideSlide>
                                      <CarouselItem item={item} />
                                  </SplideSlide>
                              ))
                            : null}
                    </SplideTrack>

                    <div className="absolute flex items-center justify-center w-full mt-4 bottom-2">
                        <div className="flex gap-4 ">
                            {sampleBanners.length > 1
                                ? sampleBanners?.map((_, index) => (
                                      <PaginationButton
                                          key={index}
                                          index={index}
                                      />
                                  ))
                                : null}
                        </div>
                    </div>
                    <div />
                </Splide>
            </div>
        </>
    )
}

export default HomepageTopSlider
